import { filterFalsyValues } from '@audioeye/common';
import { ComponentConfigurationDTO, PortalComponentType } from '@audioeye/mono-client';
import { useFeatureGate } from '@statsig/react-bindings';
import {
  PRIMARY_LINKS,
  PRIMARY_LINKS_LEGAL_HELP_CHANGES,
  PrimaryPortalComponentType,
  PrimaryPortalComponentTypeLegalHelpChanges,
} from 'joyComponents/Navigation/types';
import { createContext, useContext } from 'react';
import { Gate } from 'types/util';

import { useGetSingleSignOnConfigId } from '../state/auth/authQueries';
import { useGetPresentationConfigBySingleSignOnConfigId } from '../state/presentationConfigQueries';
import { UISettings } from './types';
import { useConvertDefaultComponentValuesToDTO } from './useConvertDefaultComponentValuesToDTO';
import { useEmulatedPresentationConfig } from './useEmulatedPresentationConfig';
import { useFilterComponentsByState } from './useFilterComponentsByState';
import { useGates } from './useGates';
import { useSiteContext } from './useSiteContext';

const useFilterComponentsByFeatureGate = (
  unfilteredComponents: ComponentConfigurationDTO[],
): ComponentConfigurationDTO[] => {
  const featureGates = unfilteredComponents
    .map(({ componentFilter }) => componentFilter?.featureGate?.name)
    .filter(filterFalsyValues);

  const gateResults = useGates(featureGates, undefined /* ignoreOverrides */, true);

  return unfilteredComponents.filter(({ componentFilter }) => {
    const { featureGate } = componentFilter ?? {};
    if (!featureGate) {
      return true;
    }
    const { name, invertValue } = featureGate;
    // if invertValue is true, we need to flip the gate result
    const { value } = gateResults[name] ?? {};
    const gateValue = invertValue ? !value : value;

    return gateValue;
  });
};

/**
 * Push feature gate checks in the link hooks up to allow the hooks to take in
 * components and filter them based on availability without performing state checks.
 */

export const useGetUISettings = ():
  | {
      data: UISettings;
      isLoading: false;
    }
  | { data: undefined; isLoading: true } => {
  const singleSignOnConfigId = useGetSingleSignOnConfigId();
  const { DEFAULT_PRESENTATION_CONFIG } = useConvertDefaultComponentValuesToDTO();
  const { data: _presentationConfig = DEFAULT_PRESENTATION_CONFIG, isLoading } =
    useGetPresentationConfigBySingleSignOnConfigId(singleSignOnConfigId);
  const { value: shouldShowAccountSettings } = useFeatureGate(Gate.ShouldShowAccountSettings);
  const { value: shouldUseNewSiteManagement } = useFeatureGate(Gate.ShouldUseNewSiteManagement);
  const { value: shouldShowLegalHelpChanges } = useFeatureGate(Gate.ShouldShowLegalHelpChanges);
  const context = useSiteContext();
  // The context may be undefined here so we need to check for it
  const site = context?.site;

  const emulatedPresentationConfig = useEmulatedPresentationConfig();

  // if an admin is emulating a config, prefer that one over their current one
  const presentationConfig = emulatedPresentationConfig ?? _presentationConfig;

  // We need to provide both of these in the hook.
  // The first one is the original components from the presentation config, filtered by the feature gates.
  // The second one is the components filtered by the state of the site, which is used in rendering our navigation
  const gateFilteredComponents = useFilterComponentsByFeatureGate(presentationConfig.componentsConfig.components);
  const stateFilteredComponents = useFilterComponentsByState(gateFilteredComponents, site);
  let primaryComponents = [];
  if (shouldShowLegalHelpChanges) {
    primaryComponents = stateFilteredComponents.filter((component) =>
      PRIMARY_LINKS_LEGAL_HELP_CHANGES.includes(component.type as PrimaryPortalComponentTypeLegalHelpChanges),
    );
  } else {
    primaryComponents = stateFilteredComponents.filter((component) =>
      PRIMARY_LINKS.includes(component.type as PrimaryPortalComponentType),
    );
  }
  const nonPrimaryComponents = stateFilteredComponents.filter(
    (component) => !PRIMARY_LINKS.includes(component.type as PrimaryPortalComponentType),
  );

  if (isLoading) {
    return { data: undefined, isLoading: true };
  }

  const { featureSettings = {} } = presentationConfig;
  const { isSingleSiteOnly = false, isJavaScriptAutoInstalled = false } = featureSettings;

  const hasComponent = (componentType: PortalComponentType) =>
    stateFilteredComponents.some((component) => component.type === componentType);
  const doesSubscriptionComponentExist = hasComponent(PortalComponentType.SUBSCRIPTION);
  const isSubscriptionManagementAndUpsellingHidden = !doesSubscriptionComponentExist;

  return {
    data: {
      integrationId: presentationConfig.integrationId,
      components: stateFilteredComponents,
      gateFilteredComponents,
      primaryComponents,
      nonPrimaryComponents,
      hasComponent,
      isSingleSiteOnly,
      isJavaScriptAutoInstalled,
      isSubscriptionManagementAndUpsellingHidden,
      shouldShowAccountSettings,
      shouldUseNewSiteManagement,
    },
    isLoading: false,
  };
};

export const UISettingsContext = createContext<UISettings>({} as UISettings);
export const useUISettingsContext = () => useContext(UISettingsContext);

export const exportedForTesting = {
  useFilterComponentsByFeatureGate,
};
