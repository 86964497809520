/**
 * General App Config globals
 */
export const APP_CONFIG = {
  site: {
    address: new URL(process.env.GATSBY_SITE_URL || 'http://localhost:8000'),
    title: 'AudioEye Customer Portal',
    description: 'AudioEye Customer Portal',
    keywords: 'audioeye, accessibility, javascript, sample, something',
    siteUrl: process.env.GATSBY_SITE_URL || 'http://localhost:8000',
    author: {
      name: 'Customer Experience Team',
      url: 'www.audioeye.com',
      email: 'engineering@audioeye.com',
    },
    siteHash: {
      production: 'ad04611401635a7de7abbfd9699c6219',
      stage: '37cd9594c426e95bac0bc1b421351925',
    }[process.env.GATSBY_ACTIVE_ENV ?? ''],
  },
  siteGrader: {
    url: process.env.GATSBY_SITE_GRADER_URL || 'https://staging-grader.audioeye.com',
  },
  api: {
    url: process.env.GATSBY_AE_API_URL || 'http://localhost:3000',
    mock: process.env.GATSBY_MOCK_API === 'true',
    e2e: process.env.NODE_ENV === 'development' && process.env.GATSBY_ACTIVE_ENV === 'e2e',
    mockAuthToken: process.env.GATSBY_MOCK_AUTH_TOKEN || 'please-add-access-token-to-env-config',
  },
  auth: {
    serviceUrl: process.env.GATSBY_AE_AUTH_API_URL || 'http://localhost:4000',
    region: process.env.GATSBY_COGNITO_REGION || 'us-west-2',
    userPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
    clientId: process.env.GATSBY_COGNITO_USER_POOL_WEB_CLIENT_ID,
    appDomain: process.env.GATSBY_COGNITO_APP_DOMAIN,
  },
  chargebee: {
    site: process.env.GATSBY_CHARGEBEE_SITE || 'audioeye-test',
  },
  audioeye: {
    cdnDomain: process.env.GATSBY_AE_CDN_DOMAIN || 'https://wsmcdnstaging.audioeye.com',
    legacyLoginUrl: process.env.GATSBY_AE_LEGACY_LOGIN_URL || 'https://portal.audioeye.com/login',
    testingVersion: '5.5.1',
  },
  statsig: {
    clientSideID: process.env.GATSBY_STATSIG_CLIENT_ID ?? 'client-key',
    envTier: process.env.GATSBY_ACTIVE_ENV === 'stage' ? 'staging' : process.env.GATSBY_ACTIVE_ENV || 'development',
    apiProxy: process.env.GATSBY_STATSIG_API_PROXY,
  },
  hubspot: {
    expertAuditFormId: process.env.GATSBY_HUBSPOT_EXPERT_AUDIT_FORM_ID || '9f17f8b5-98dc-4c33-a639-a07cdec6ac26',
    upgradeProtectionFormId:
      process.env.GATSBY_HUBSPOT_UPGRADE_PROTECTION_FORM_ID || 'bd0db1d9-f268-4f82-8fbe-feca7aaac4f5',
    portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID || '39831836',
  },
  garr_e: {
    serviceUrl: process.env.GATSBY_GARR_E_API_URL ?? 'https://garr-e.garr-e-stage.dev-audioeye.com',
  },
  paragon: {
    projectId:
      process.env.GATSBY_ACTIVE_ENV === 'production'
        ? '8f56522d-c6ee-48d5-b173-45aba7b3132b'
        : '0119038e-2a77-4f0e-b816-107e0485ef2e',
  },
};
